const get_API_URI = () => {
  if (process.env.API_URI) {
    return process.env.API_URI;
  } else {
    return "https://api.homecare.life";
  }
};
export const API_URI = get_API_URI();


const get_DOMAIN = () => {
  if (process.env.DOMAIN) {
    return process.env.DOMAIN;
  } else {
    return "https://lifehospice.com";
  }
};
export const DOMAIN = get_DOMAIN();
